import { camelCase, omit } from "lodash";
import React from "react";
import airtable from "./brands/airtable.svg";
import bigcommerce from "./brands/bigcommerce.svg";
import demandware from "./brands/demandware.svg";
import discord from "./brands/discord.svg";
import discordSimple from "./brands/discordSimple.svg";
import email from "./brands/email.svg";
import facebook from "./brands/facebook.svg";
import facebookCircle from "./brands/facebookCircle.svg";
import figma from "./brands/figma.svg";
import google from "./brands/google.svg";
import googlesheets from "./brands/googlesheets.svg";
import hubspot from "./brands/hubspot.svg";
import klaviyo from "./brands/klaviyo.svg";
import magento from "./brands/magento.svg";
import mailchimp from "./brands/mailchimp.svg";
import msteams from "./brands/msteams.svg";
import mysql from "./brands/mysql.svg";
import netsuite from "./brands/netsuite.svg";
import openai from "./brands/openai.svg";
import postgresql from "./brands/postgresql.svg";
import salesforce from "./brands/salesforce.svg";
import sendgrid from "./brands/sendgrid.svg";
import sentry from "./brands/sentry.svg";
import shopify from "./brands/shopify.svg";
import slack from "./brands/slack.svg";
import squarespace from "./brands/squarespace.svg";
import stripe from "./brands/stripe.svg";
import twilio from "./brands/twilio.svg";
import twitter from "./brands/twitter.svg";
import webflow from "./brands/webflow.svg";
import wix from "./brands/wix.svg";
import woocommerce from "./brands/woocommerce.svg";
import zapier from "./brands/zapier.svg";
import zendesk from "./brands/zendesk.svg";

export type ThirdPartyIconType = keyof typeof ThirdPartyUrls;

export const ThirdPartyIcon = (props: { type: ThirdPartyIconType; $size?: number; $color?: string }) => {
  const size = props.$size ?? 16;
  const type = camelCase(props.type);
  const url = ThirdPartyUrls[type as keyof typeof ThirdPartyUrls];

  if (props.$color) {
    return (
      // Setting the div to 100% height and width allows the contain value for mask to fit the icon correctly to its parent.
      // More importantly, this is the same behaviour that the img (below) has, thus mimicking it.
      <div style={{ width: "100%", height: "100%", backgroundColor: props.$color, mask: `url(${url}) no-repeat center / contain` }}></div>
    );
  }

  return <img {...omit(props, "type", "$size")} width={size} height={size} src={url} />;
};

const ThirdPartyUrls = {
  stripe,
  slack,
  google,
  magento,
  twilio,
  shopify,
  sendgrid,
  discord,
  zapier,
  postgresql,
  mysql,
  facebook,
  twitter,
  mailchimp,
  bigcommerce,
  woocommerce,
  airtable,
  googlesheets,
  sentry,
  facebookCircle,
  hubspot,
  klaviyo,
  wix,
  figma,
  msteams,
  zendesk,
  discordSimple,
  salesforce,
  squarespace,
  webflow,
  netsuite,
  demandware,
  openai,
  email,
} as const;
